import React from 'react'

export default function Footer2() {
  return (
    <div className='bg-transparent py-10 flex justify-center '>
       <div className='w-[95%] flex flex-col gap-3'>
       <div className='w-[95%] flex flex-col gap-2 lg:flex-row justify-between items-center'>
          <div className='flex  lg:gap-10 gap-3'>
           <a className='text-white font-inter'>About Us</a>
           <a href='/Products' className='text-white font-inter'>Products</a>
           <a href='/Service' className='text-white font-inter'>Services</a>
           <a href="/Contactus" className='text-white font-inter'>Contact Us</a>
          </div>
          <div className='flex gap-8'>
          <a href='https://www.linkedin.com/company/fabc-labs' target='_blank'><i class="fa-brands fa-linkedin-in" style={{color: "#f3f4f7"}}></i></a>
          <a href='https://x.com/FabcL8861?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-x-twitter" style={{color: "#f3f4f7"}}></i></a>
          <a href='https://www.facebook.com/fabclabsllc/?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-facebook" style={{color: "#f3f4f7"}}></i></a>
          <a href='https://www.instagram.com/fabc_labs/?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-instagram" style={{color: "#f3f4f7"}}></i></a>
          </div>
          </div>
        <div className='w-[98%] border border-white'></div>
        <div className='flex flex-col gap-5 lg:flex-row justify-start lg:gap-20'>
         <div className='flex justify-center items-center gap-4'>
         <i class="fa-solid fa-location-dot" style={{color: "#f3f4f7"}}></i>
         <p className='text-white font-inter'>First Academy of Blockchain Council No: 51, Ground Floor, Tower A Tek Meadows, OMR Sholinganallur, Chennai</p>
         </div>
         <div className='flex justify-start lg:justify-center items-center gap-4'>
         <i class="fa-solid fa-envelope" style={{color: "#f3f4f7"}}></i>
         <p className='text-white font-inter'>support@fabc.global</p>
         </div>
         <div className='flex justify-start lg:justify-center items-center gap-4'>
         <i class="fa-solid fa-phone" style={{color: "#f3f4f7"}}></i>
         <p className='text-white font-inter'>+91 9566299429</p>
         </div>
        </div>
       </div>
    </div>
  )
}

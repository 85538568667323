import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import Header from './Header';

import Landing from './Landing';
import Products from './Products';
import Service from './Service';
import ContactUs from './ContactUs';
function Routers() {
    return (
      <Routes>
      <Route path="/" element={<Landing/>} />
      <Route path="/Products" element={<Products/>} />
      <Route path="/Service" element={<Service/>} />
      <Route path="/Contactus" element={<ContactUs/>} />

      <Route path="/header" element={<Header/>} />





       </Routes>
  );
}

export default Routers;
import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Landing() {
  return (
    <div className='font-inter text-white'>
      <div className='z-50'>
        <Header />
      </div>
      <div className='hidden lg:block  Main-container Desktop-screen'>
        <div className='Section-1 flex items-center justify-center'>
          <video
            autoPlay
            loop
            muted
            playsinline
            className=" z-0 h-full  "
          >
            <source src="/assets/Main Banner BG.mp4" type="video/mp4" />
          </video>
          <div className='flex items-end justify-center bg-gradient-to-b from-[#0e1837]/5 via-[#0e1837]/25 to-[#0e1837] absolute  2xl:h-[1038px] xl:h-[729px] lg:h-[691px]  w-full py-10'>
            <div className='w-[80%] mt-40'>
              <div className=''>
                <div className='flex justify-center'>
                  <div className='w-[98%] border bg-[#191c21] bg-opacity-80 py-8 px-2'>
                    <div className='flex justify-between -translate-y-10'>
                      <img className='w-[1%] animate-moveRight' src='/assets/Rectangle 51.png'></img>
                      <div className='translate-x-7'>
                        <img className='w-[50%] animate-moveUp ' src='/assets/Rectangle 51.png'></img>
                      </div>
                    </div>
                    <p className='font-jura text-white text-5xl text-center font-bold text-shadow-custom '>Bring your vision to life with advanced technology at our innovation hub</p>
                    <div className='flex justify-between translate-y-10'>
                      <div className='-translate-x-4'>
                        <img className='w-[50%] animate-moveDown' src='/assets/Rectangle 51.png'></img>
                      </div>
                      <img className='w-[1%] animate-moveLeft' src='/assets/Rectangle 51.png'></img>
                    </div>
                  </div>
                </div>





              </div>
              <div className='flex justify-center'>
                <div className='w-[80%] mt-5'>
                  <p className='font-inter text-white text-center text-3xl'>We transform ideas into market - Ready products, Embrace the future with advanced technologies.</p>
                </div>
              </div>
              <div className='flex justify-center mt-5'>
                <button className="relative overflow-hidden text-white px-5 py-3 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400  shadow-md">
                  LEARN MORE
                  <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className=' Section-2 relative flex items-center justify-center '>
          <video
            autoPlay
            loop
            muted
            playsinline
            className=" z-0  w-full absolute h-full object-cover "
          >
            <source src="/assets/Other Section  Nodes.mp4" type="video/mp4" />
          </video>
          <div className='flex justify-center items-center bg-gradient-to-b from-[#0e1837] via-[#0e1837]/85 to-[#0e1837]/30  z-20 lg:h-[100vh] xl:h-[120vh] 2xl:h-[110vh] w-full pb-10 pt-10'>
            <div className='w-[80%] flex flex-col lg:flex-row justify-center pt-5 bg-cover bg-center bg-no-repeat pb-10' style={{ backgroundImage: "url('/assets/Group 1 (15).png')" }}>
              <div className='lg:w-[45%] lg:-translate-x-10'>
                <div className='border-[5px] border-[#144ead] lg:w-[85%] lg:overflow-hidden'>
                  <img className='   hover:scale-110 duration-500' src='/assets/Default_A_futuristic_highly_detailed_3D_render_of_an_innovativ_3 1.png'></img>
                </div>
              </div>
              <div className='lg:w-[55%]'>
                <div className='flex justify-center'>
                  <img src='/assets/Ellipse 3.png' className='w-[5%]'></img>
                </div>
                <div>
                  <p className='text-white font-inter font-bold text-3xl text-center 2xl:pt-5 xl:pt-5'>A&nbsp;&nbsp;&nbsp;B&nbsp;&nbsp;&nbsp;O&nbsp;&nbsp;&nbsp;U&nbsp;&nbsp;&nbsp;T&nbsp;&nbsp;&nbsp; U&nbsp;&nbsp;&nbsp;S</p>
                  <div className='flex justify-center'>
                    <div className='w-[80%]'>
                      <img className='animate-moveSmall lg:animate-moveLarge' src='/assets/Subtract (1).png'></img>
                    </div>
                  </div>
                  <div className='flex justify-center'>
                    <p className='border border-b w-[80%]'></p>
                  </div>
                </div>
                <div>
                  <p className='text-center text-white font-inter font-bold text-xl mt-5'>From Vision to victory with advance technologies</p>
                  <p className='text-white mt-4  2xl:text-xl '>We invest in your vision, using our expertise and advanced technologies to transform it into market ready products, empowering you to embrace the future</p>
                  <p className='text-white mt-4 2xl:text-xl '><span className='font-bold text-[#e8ba3c]'>BEGINING:</span>FABC Labs started with a simple yet ambitious goal: to create a hub where groundbreaking ideas could flourish and evolve into market ready solutions</p>
                  <p className='text-white mt-4 2xl:text-xl '><span className='font-bold text-[#e8ba3c]'>GROWTH:</span> As an entrepreneurial innovation hub, we are dedicated to developing revolutionary technologies that drive efficiency, transparency, and growth across various industries.</p>
                  <p className='text-white mt-4 2xl:text-xl '><span className='font-bold text-[#e8ba3c]'>TODAY:</span>Our commitment to pushing boundaries and turning visionary concepts into tangible realities is fueled by our entrepreneurial spirit.</p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className=' Section-3 '>
          <div className='bg-cover bg-center bg-no-repeat pb-10 py-20' style={{ backgroundImage: "url('/assets/Group 1 (16).png')" }}>
            <div>
              <div className='flex justify-center'>
                <p className='text-white font-inter text-center text-4xl uppercase '>O&nbsp;u&nbsp;r&nbsp;&nbsp; F&nbsp;l&nbsp;a&nbsp;g&nbsp;s&nbsp;h&nbsp;i&nbsp;p <br></br>P&nbsp;r&nbsp;o&nbsp;d&nbsp;u&nbsp;c&nbsp;t&nbsp;s&nbsp;</p>
              </div>
              <div className='flex justify-center'>
                <div className='w-[30%]'>
                  <img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img>
                </div>
              </div>
              <div className='flex justify-center'>
                <p className='border border-b w-[30%]'></p>
              </div>
            </div>
            <div className='flex justify-center gap-5 mt-10 px-10'>
              <div className='w-[24%] '>
                <div>
                  <img src='/assets/Group 1597883179.png'></img>
                </div>
                <div className='bg-white py-4 px-3 flex gap-4 items-center justify-center'>
                  <p className='text-center text-black font-bold font-inter'>Skills Marketplace </p>
                  <img className='animate-moveSmall1' src='/assets/Subtract (2).png'></img>
                </div>
              </div>
              <div className='w-[15%]'>
                <div>
                  <img src='/assets/Rectangle 71.png'></img>
                </div>
                <div className='flex justify-center mt-5'>
                  <p className='border border-b text-center w-[10%]'></p>
                </div>
                <div className='mt-2'>
                  <p className='font-inter text-center text-white'>Decentralized AI BOTs <br></br>Solutions</p>
                </div>

              </div>
              <div className='w-[15%]'>
                <div>
                  <img src='/assets/Group 1597883180.png'></img>
                </div>
                <div className='flex justify-center mt-5'>
                  <p className='border border-b text-center w-[10%]'></p>
                </div>
                <div className='mt-2'>
                  <p className='font-inter text-center text-white'>RWA Tokenization</p>
                </div>

              </div>
              <div className='w-[15%]'>
                <div>
                  <img src='/assets/Group 1597883180 (1).png'></img>
                </div>
                <div className='flex justify-center mt-5'>
                  <p className='border border-b text-center w-[10%]'></p>
                </div>
                <div className='mt-2'>
                  <p className='font-inter text-center text-white'>Track & Trace Food Chain <br></br>and Health Chain</p>
                </div>

              </div>
              <div className='w-[15%]'>
                <div>
                  <img src='/assets/Rectangle 71 (1).png'></img>
                </div>
                <div className='flex justify-center mt-5'>
                  <p className='border border-b text-center w-[10%]'></p>
                </div>
                <div className='mt-2'>
                  <p className='font-inter text-center text-white'>Other Research and <br></br> Development</p>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className='Section-4 bg-cover bg-center bg-no-repeat' style={{ backgroundImage: "url('/assets/Group 1597883195.png')" }}>
          <div className='py-20   grid content-center text-center relative'>
            <h1 className='uppercase tracking-widest text-white text-4xl uppercase font-medium'>Our Expertise</h1>
            <div className='w-[30%] mx-auto'>
              <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
              <p className='border-b border-white/50'></p>
            </div>
            <div className='flex flex-col w-[90%] mx-auto mt-5'>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/1o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='px-5 py-16 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 01</div>
                    <div className='text-lg md:text-2xl uppercase text-white font-semibold tracking-widest'>WEB3 Digital Marketing</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='px-5 py-16 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>WEB3 Digital Marketing</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#01</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/4o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='px-5 py-16 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 02</div>
                    <div className='text-lg md:text-2xl uppercase text-white font-semibold tracking-widest'>Solution Development</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='px-5 py-16 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>Solution Development</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#02</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/2o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='px-5 py-16 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 03</div>
                    <div className='text-lg md:text-2xl uppercase text-white font-semibold tracking-widest'>Research & Development</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='px-5 py-16 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>Research & Development</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#03</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full '>
                  <img src='/assets/3o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='px-5 py-16 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 04</div>
                    <div className='text-lg md:text-2xl uppercase text-white font-semibold tracking-widest'>White-label Solution</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='px-5 py-16 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>White-label Solution</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#04</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/4o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='px-5 py-16 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 05</div>
                    <div className='text-lg md:text-2xl uppercase text-white font-semibold  tracking-widest'>PartnershipS</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='px-5 py-16 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>PartnershipS</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#02</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='Section-5  bg-gradient-to-b from-[#185CC2D9] via-[#0A2973D9] to-[#9e9055]/80'>
          <div className='py-20 bg-black/30 backdrop-blur-sm grid content-center text-center relative'>
            <h1 className='uppercase tracking-widest text-white text-2xl md:text-4xl 2xl:text-5xl font-medium'>WHy FABC LABS</h1>
            <div className='w-[30%] mx-auto'>
              <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
              <p className='border-b border-white/50'></p>
            </div>
            <div className='flex flex-row gap-5 md:gap-0  overflow-x-auto justify-around w-[90%] mx-auto mt-5'>
              <div className='w-[90%] md:w-[30%] flex-shrink-0'>
                <img src='/assets/c1.png'></img>
                <p className=' font-bold mt-2 text-lg'>Expertise in Advanced Technologies:</p>
                <p className=' text-white/60'>We specialize in blockchain, AI, DeFi, and NFTs, staying ahead of industry trends.</p>
                <button className='border border-[#E8BA3C] rounded-xl px-5 py-1 mt-2'>Read more</button>
              </div>
              <div className='w-[90%] md:w-[30%] text-start flex-shrink-0'>
                <img src='/assets/c2.png'></img>
                <p className=' font-bold mt-2'> Proven Innovation:</p>
                <p className=' text-white/60'>Turning visionary ideas into succesful products. Driving innovation with a commitment to growth</p>
                <hr className='my-3 w-[20%]' />
                <button className='text-[#E8BA3C] text-sm'>Read more</button>
              </div>
              <div className='w-[90%] md:w-[30%] text-start flex-shrink-0'>
                <img src='/assets/c3.png'></img>
                <p className=' font-bold mt-2'>Customized solutions</p>
                <p className=' text-white/60'>An innovation hub and Consultation platform, Tailored to meet your unique challenges and goals</p>
                <hr className='my-3 w-[20%]' />
                <button className='text-[#E8BA3C] text-sm'>Read more</button>
              </div>
            </div>
            <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500 blur-sm"></span>
          </div>
        </div>
        <div className='Section-6 overflow-hidden bg-gradient-to-t from-[#185CC2D9] via-[#0A2973D9] to-[#9e9055]/80'>
          <div className='pt-20 pb-40 md:pb-0 bg-black/30 backdrop-blur-sm grid content-center text-center'>
            <h1 className='uppercase tracking-widest text-white text-2xl md:text-4xl 2xl:text-5xl font-medium'>How innovative ideas work <br className='hidden md:block' /> with FABC labs?</h1>
            <div className='w-[55%] mx-auto'>
              <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
              <p className='border-b border-white/50'></p>
            </div>
            <div className='relative '>
              <div className='relative flex justify-center items-center '>
                <img src='/assets/dd3d 1.png' className='w-[75%] md:w-[40%] brightness-75 mx-auto z-10'></img>
                <div className='-translate-y-20 absolute w-[10rem] h-[10rem] md:w-[20rem] md:h-[20rem] border border-white/30 rounded-full z-0 animate-pingslow transform transition-transform duration-500 ease-in-out'></div>
                <div className='-translate-y-20 absolute w-[15rem] h-[15rem] md:w-[30rem] md:h-[30rem] border border-white/40 rounded-full z-0 animate-pingslow2 transform transition-transform duration-500 ease-in-out'></div>
                <div className='-translate-y-20 absolute w-[20rem] h-[20rem] md:w-[40rem] md:h-[40rem] border border-white/50 rounded-full z-0 animate-pingslow3 transform transition-transform duration-500 ease-in-out'></div>
              </div>
              <div className='absolute -bottom-[10rem] md:bottom-20 z-10'>
                <div className='w-[90%] relative bg-[#0E1837]/45 backdrop-blur-sm border border-[#FFBB00A6] mx-auto p-10 flex flex-col md:flex-row gap-5 md:gap-0'>
                  <div className='md:w-[75%] text-start'><p>An Innovation Hub and a Consultation Platform- a one-stop solution for assistance in building blockchain-related solutions, developing prototypes, and exploring emerging technologies such as Generative AI.</p></div>
                  <div className='md:w-[25%] flex justify-center items-center'>
                    <button className="relative overflow-hidden px-5 py-3 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400  shadow-md">
                      Explore Opportunities
                      <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
                    </button>

                  </div>
                  <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500 blur-sm"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Section-7  bg-gradient-to-b from-[#185CC2D9] via-[#0A2973D9] to-[#9e9055]/80'>
          <div className='py-20 bg-black/30 backdrop-blur-sm grid content-center text-center'>
            <h1 className='uppercase tracking-widest text-4xl 2xl:text-5xl font-medium'>How do we innovate?</h1>
            <div className='w-[50%] mx-auto'>
              <img className='animate-moveSmall lg:animate-moveLarge' src='/assets/Subtract (1).png'></img>
              <p className='border-b border-white/50'></p>
            </div>
            <p className='w-[90%] mx-auto mt-5 text-lg 2xl:text-2xl'>We are committed to pushing the boundaries of technology, exploring the uncharted, and transforming visionary ideas into tangible solutions.</p>
            <div className='flex lg:justify-center gap-5 w-[90%] lg:w-[95%] mx-auto mt-10 overflow-x-auto '>
              <div className='flex-shrink-0 w-[90%] md:w-[40%] lg:w-[23%] bg-[#1B2D65] border-[2px] border-[#4F93FF] px-2 pt-2'>
                <img src='/assets/Mask group.png' className='mx-auto'></img>
                <p className='text-white/55 my-3'>Tailored solutions that deliver impactful, relevant results.</p>
                <p className='uppercase font-semibold tracking-widest'>Client-Centric <br />Innovation</p>
                <div className='flex justify-center'>
                  <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5'>02</p>
                </div>
              </div>
              <div className='flex-shrink-0 w-[90%] md:w-[40%] lg:w-[23%] relative flex items-start group'>
                <div className='opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7 w-full'>
                  <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
                  <img src='/assets/CE_01.png' className='mx-auto w-[75%] mt-5'></img>
                  <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest'>Collaborative</p>
                  <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest'>Ecosystem</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7'>01</p>
                  </div>
                </div>
                <div className='absolute opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65] border-[2px] border-[#4F93FF] px-2 pt-2'>
                  <img src='/assets/Mask group.png' className='mx-auto'></img>
                  <p className='text-white/55 my-3'>Tailored solutions that deliver impactful, relevant results.</p>
                  <p className='uppercase font-semibold tracking-widest'>Collaborative Ecosystem</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5'>01</p>
                  </div>
                </div>
              </div>

              <div className='flex-shrink-0 w-[90%] md:w-[40%] lg:w-[23%] relative flex items-start group'>
                <div className='w-full opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7'>
                  <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
                  <img src='/assets/CE_03.png' className='mx-auto w-[75%] mt-5'></img>
                  <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest'>Continuous</p>
                  <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest'>R & D</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7'>03</p>
                  </div>
                </div>
                <div className='absolute opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65] border-[2px] border-[#4F93FF] px-2 pt-2'>
                  <img src='/assets/Mask group.png' className='mx-auto'></img>
                  <p className='text-white/55 my-3'>Tailored solutions that deliver impactful, relevant results.</p>
                  <p className='uppercase font-semibold tracking-widest'>Continuous <br /> R & D</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5'>03</p>
                  </div>
                </div>
              </div>
              <div className='flex-shrink-0 w-[90%] md:w-[40%] lg:w-[23%] relative flex items-start group'>
                <div className='w-full opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7'>
                  <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
                  <img src='/assets/CE_04.png' className='mx-auto w-[75%] mt-5'></img>
                  <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest'>Flexibility</p>
                  <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest'>and Agility</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[20%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7'>04</p>
                  </div>
                </div>
                <div className='absolute opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65] border-[2px] border-[#4F93FF] px-2 pt-2'>
                  <img src='/assets/Mask group.png' className='mx-auto'></img>
                  <p className='text-white/55 my-3'>Tailored solutions that deliver impactful, relevant results.</p>
                  <p className='uppercase font-semibold tracking-widest'>Flexibility<br /> And Agility</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5'>04</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Section-8 relative flex flex-col md:flex-row items-center justify-center'>
          <video
            autoPlay
            loop
            muted
            playsinline
            className="absolute z-0  w-full h-full object-cover">
            <source src="/assets/Other Section  Nodes.mp4" type="video/mp4" />
          </video>
          <div className='text-center bg-gradient-to-b from-[#0e1837]/85 via-[#0e1837] to-[#0e1837]/90  z-20 min-h-screen py-10 w-[100%] grid content-center'>
            <h1 className='uppercase tracking-widest text-4xl 2xl:text-5xl font-medium'>Our Leaders </h1>
            <div className='w-[32%] mx-auto'>
              <img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img>
              <p className='border-b border-white/50'></p>
            </div>
            <p className='w-[90%] mx-auto mt-5 text-lg 2xl:text-2xl'>At FABC, we're proud to collaborate with a diverse network of global Consortium partners who share our commitment to innovation and excellence. These strategic alliances are the cornerstone of our success, enabling us to deliver cutting-edge solutions and drive positive change across industries.</p>
            <div className='flex flex-col md:flex-row justify-around w-[90%] mx-auto mt-5'>
              <div className='md:w-[25%] relative flex justify-center'>
                <img src='/assets/sak.png'></img>
                <div className='absolute bottom-0'>
                  <p className='font-bold uppercase'>Sakthi Visakan <br />Rajaguru</p>
                  <p className='text-xl mt-2 text-[#E8BA3C] font-medium'>CEO</p>
                </div>
              </div>
              <div className='md:w-[25%] relative flex justify-center'>
                <img src='/assets/ann.png'></img>
                <div className='absolute bottom-0'>
                  <p className='font-bold uppercase'>Annamalai <br /> Palaniappan</p>
                  <p className='text-xl mt-2 text-[#E8BA3C] font-medium'>COO</p>
                </div>
              </div>
              <div className='md:w-[25%] relative flex justify-center'>
                <img src='/assets/anu.png'></img>
                <div className='absolute bottom-0'>
                  <p className='font-bold uppercase'>Anup <br />Kumar</p>
                  <p className='text-xl mt-2 text-[#E8BA3C] font-medium'>CTO</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='block lg:hidden'>
        <div className='Section-1  justify-center'>
          <video
            autoPlay
            loop
            muted
            playsinline
            className=" z-0 h-full  "
          >
            <source src="/assets/Main Banner BG.mp4" type="video/mp4" />
          </video>
          <div className='flex items-end justify-center bg-[#0e1837]   z-20  w-full py-10'>
            <div className='w-[80%] '>
              <div className=''>



                <div className='flex justify-center'>
                  <div className='w-[98%] border-[#fff] border-2  py-8 bg-gradient-to-br from-[#0d2262] via-[#0d2236] to-[#E8BA3C80] ' >
                    <div className='flex justify-between -translate-y-10'>
                      <img className='w-[3%] animate-moveRighter' src='/assets/Rectangle 51.png'></img>
                      <div className='translate-x-4'>
                        <img className='w-[80%] animate-moveUpper ' src='/assets/Rectangle 51.png'></img>
                      </div>
                    </div>
                    <p className='font-play text-white text-2xl text-center '>Invest in Your Vi<span className='text-[#E8BA3C] font-play '>sion</span> <br></br>
                      <span className='text-[#E8BA3C] font-play '><span className='text-white font-play'>with leading </span>innovation <span className='text-white font-play'>hub wit</span>h web 3 tech</span></p>
                    <div className='flex justify-between translate-y-10'>
                      <div className='-translate-x-3'>
                        <img className='w-[80%] animate-moveDowner' src='/assets/Rectangle 51.png'></img>
                      </div>
                      <img className='w-[3%] animate-moveLefter' src='/assets/Rectangle 51.png'></img>
                    </div>
                  </div>
                </div>



              </div>
              <div className='flex justify-center'>
                <div className='w-[70%] '>
                  <p className='font-inter text-white text-center lg:text-3xl mt-5 lg:mt-0'>We transform ideas into market. Ready products, Embrace the Future</p>
                </div>
              </div>
              <div className='flex justify-center mt-5'>
                <button className="relative overflow-hidden text-white px-5 py-3 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400  shadow-md">
                  LEARN MORE
                  <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
                </button>
              </div>
            </div>

          </div>

        </div>
        <div className='Section-2'>
          <div className='Section-2/1'>
            <div className='w-[100%] flex flex-col lg:flex-row justify-center  bg-cover bg-center bg-no-repeat py-5' style={{ backgroundImage: "url('/assets/Group 1 (15).png')" }}>
              <div>
                <p className='text-white font-inter font-bold text-3xl text-center '>A&nbsp;&nbsp;&nbsp;B&nbsp;&nbsp;&nbsp;O&nbsp;&nbsp;&nbsp;U&nbsp;&nbsp;&nbsp;T&nbsp;&nbsp;&nbsp; U&nbsp;&nbsp;&nbsp;S</p>
                <div className='flex justify-center'>
                  <div className='w-[80%]'>
                    <img className='animate-moveSmall lg:animate-moveLarge' src='/assets/Subtract (1).png'></img>
                  </div>
                </div>
                <div className='flex justify-center'>
                  <p className='border border-b w-[80%]'></p>
                </div>
              </div>
              <div className='lg:w-[45%] px-5 mt-16 lg:-translate-x-10'>
                <div className='border-[5px] border-[#144ead]  overflow-hidden'>
                  <img className=' w-full  hover:scale-110 duration-500' src='/assets/Default_A_futuristic_highly_detailed_3D_render_of_an_innovativ_3 1.png'></img>
                </div>
              </div>

            </div>


          </div>
          <div className='Sectiom-2/2 flex items-center justify-center '>
            <video
              autoPlay
              loop
              muted
              playsinline
              className=" z-0  w-full h-[419px]  object-cover "
            >
              <source src="/assets/Other Section  Nodes.mp4" type="video/mp4" />
            </video>
            <div className='flex justify-center bg-gradient-to-b from-[#0e1837] via-[#0e1837]/85 to-[#0e1837]/70 absolute z-20 w-full h-[419px] py-10'>
              <div className='h-[219px]'>
                <div className='lg:w-[55%] '>
                  <div className='flex justify-center'>
                    <img className='-translate-y-20' src='/assets/Ellipse 3.png'></img>
                  </div>


                </div>
                <div className='-translate-y-20 px-2'>
                  <p className='text-center text-white/65  font-inter font-bold text-sm mt-5'>we believe in the transformative power of innovation. Our journey began with a simple yet ambitious goal: to create a hub where groundbreaking ideas can flourish and evolve into market-ready solutions. As a leading-edge innovation hub, we are dedicated to cultivating and developing revolutionary technologies that drive efficiency, transparency, and growth across various industries.</p>
                  <p className='text-white text-sm mt-4 '>We invest in your vision, utilize our expertise, and harness Web 3 technology to transform it into market ready products, allowing you to embrace the future.</p>

                </div>
                <div className='flex justify-center mt-5 -translate-y-20'>
                  <a href='/Contactus'>  <button className="relative overflow-hidden text-white px-5 py-3 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400  shadow-md">
                    CONTACT US
                    <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='Section-3'>
          <div className=' Section-3 '>
            <div className='bg-cover bg-center bg-no-repeat pb-10 py-5' style={{ backgroundImage: "url('/assets/Group 1 (16).png')" }}>
              <div>
                <div className='flex justify-center'>
                  <p className='text-white font-inter text-center text-4xl uppercase '>O&nbsp;u&nbsp;r&nbsp;&nbsp; I&nbsp;N&nbsp;V&nbsp;E&nbsp;N&nbsp;T&nbsp;I&nbsp;O&nbsp;N</p>
                </div>
                <div className='flex justify-center'>
                  <div className='w-[70%]'>
                    <img className='animate-moveSmaller ' src='/assets/Subtract (1).png'></img>
                  </div>
                </div>
                <div className='flex justify-center'>
                  <p className='border border-b w-[70%]'></p>
                </div>
              </div>
              <div className='flex lg:justify-start  gap-5 mt-10 px-10 overflow-x-scroll'>
                <div className='w-[80%] md:w-[60%] flex flex-col shrink-0'>
                  <div>
                    <img src='/assets/Group 1597883179.png'></img>
                  </div>
                  <div className='bg-white py-4 px-3 flex gap-4 items-center justify-center'>
                    <p className='text-center text-black font-inter font-bold'>Skills Marketplace </p>
                    <img className='animate-moveSmall1' src='/assets/Subtract (2).png'></img>
                  </div>
                </div>
                <div className='w-[80%] md:w-[60%] flex flex-col shrink-0'>
                  <div className='flex justify-center'>
                    <img src='/assets/Rectangle 71.png'></img>
                  </div>
                  <div className='flex justify-center mt-5'>
                    <p className='border border-b text-center w-[10%]'></p>
                  </div>
                  <div className='mt-2'>
                    <p className='font-inter text-center text-white'>Decentralized AI BOTs <br></br>Solutions</p>
                  </div>

                </div>
                <div className='w-[80%] md:w-[60%] flex flex-col shrink-0'>
                  <div className='flex justify-center'>
                    <img src='/assets/Group 1597883180.png'></img>
                  </div>
                  <div className='flex justify-center mt-5'>
                    <p className='border border-b text-center w-[10%]'></p>
                  </div>
                  <div className='mt-2'>
                    <p className='font-inter text-center text-white'>RWA Tokenization</p>
                  </div>

                </div>
                <div className='w-[80%] md:w-[60%] flex flex-col shrink-0'>
                  <div className='flex justify-center'>
                    <img src='/assets/Group 1597883180 (1).png'></img>
                  </div>
                  <div className='flex justify-center mt-5'>
                    <p className='border border-b text-center w-[10%]'></p>
                  </div>
                  <div className='mt-2'>
                    <p className='font-inter text-center text-white'>Track & Trace Food Chain <br></br>and Health Chain</p>
                  </div>

                </div>
                <div className='w-[80%] md:w-[60%] flex flex-col shrink-0'>
                  <div className='flex justify-center'>
                    <img src='/assets/Rectangle 71 (1).png'></img>
                  </div>
                  <div className='flex justify-center mt-5'>
                    <p className='border border-b text-center w-[10%]'></p>
                  </div>
                  <div className='mt-2'>
                    <p className='font-inter text-center text-white'>Other Research and <br></br> Development</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Section-4 bg-gradient-to-b from-[#185CC2D9] via-[#0A2973D9] to-[#9e9055]/80'>
          <div className='py-20 bg-gradient-to-b from-black/60 to-black/30 backdrop-blur-sm grid content-center text-center relative'>
            <h1 className='uppercase tracking-widest text-2xl md:text-4xl 2xl:text-5xl font-medium'>Our Expertise</h1>
            <div className='w-[35%] mx-auto'>
              <div><img className='animate-move' src='/assets/Subtract (1).png'></img></div>
              <p className='border-b border-white/50'></p>
            </div>
            <div className='flex flex-col w-[90%] mx-auto mt-5'>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/1o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='p-5 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 01</div>
                    <div className='text-lg md:text-2xl uppercase font-semibold tracking-widest'>WEB3 Digital Marketing</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='p-5 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>WEB3 Digital Marketing</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#01</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/4o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='p-5 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 02</div>
                    <div className='text-lg md:text-2xl uppercase font-semibold tracking-widest'>Solution Development</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='p-5 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>Solution Development</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#02</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/2o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='p-5 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 03</div>
                    <div className='text-lg md:text-2xl uppercase font-semibold tracking-widest'>Research & Development</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='p-5 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>Research & Development</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#03</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/3o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='p-5 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 04</div>
                    <div className='text-lg md:text-2xl uppercase font-semibold tracking-widest'>White-label Solution</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='p-5 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>White-label Solution</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#04</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative cursor-pointer group flex items-center'>
                <div className='content-1 relative opacity-100 group-hover:opacity-0 duration-700 w-full'>
                  <img src='/assets/4o.png' className='absolute top-0 left-0 w-full h-full z-0 object-cover'></img>
                  <div className='p-5 flex z-10 bg-gradient-to-r from-[#29449D] to-black/30  relative items-center border-2 border-[#e7b93c]/50 md:h-[96px]'>
                    <div className='w-[20%] text-center text-xl md:text-3xl text-[#E8BA3C] font-medium'># 05</div>
                    <div className='text-lg md:text-2xl uppercase font-semibold  tracking-widest'>PartnershipS</div>
                  </div>
                </div>
                <div className='absolute w-full z-20 content-2 opacity-0 group-hover:opacity-100 duration-700 bg-gradient-to-r from-[#FFEFC2] to-[#B68500] '>
                  <div className='p-5 flex flex-col md:flex-row'>
                    <div className='md:w-[70%] tracking-widest'>
                      <h1 className='text-[#0A2973] font-bold md:text-2xl  uppercase'>PartnershipS</h1>
                      <p className='text-black text-sm'>Tailor made solutions to transform ideas into scalable, impactful products.</p>
                    </div>
                    <div className='md:w-[30%] flex justify-center gap-5 items-center'>
                      <p className='text-black/20 md:text-4xl'>#02</p>
                      <button className='px-5 md:px-10 py-1 border border-[#0A2973] text-[#0A2973] shadow-md shadow-[#0A2973]/50'>Explore</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='Section-5  bg-gradient-to-b from-[#185CC2D9] via-[#0A2973D9] to-[#9e9055]/80'>
          <div className='py-20 bg-black/30 backdrop-blur-sm grid content-center text-center relative'>
            <h1 className='uppercase tracking-widest text-2xl md:text-4xl 2xl:text-5xl font-medium'>WHy FABC LABS</h1>
            <div className='w-[35%] mx-auto'>
              <div><img className='animate-move' src='/assets/Subtract (1).png'></img></div>
              <p className='border-b border-white/50'></p>
            </div>
            <div className='flex flex-row gap-5 md:gap-0  overflow-x-auto justify-around w-[90%] mx-auto mt-5'>
              <div className='w-[90%] md:w-[30%] flex-shrink-0'>
                <img src='/assets/c1.png'></img>
                <p className=' font-bold mt-2 text-lg'>Expertise in Advanced Technologies:</p>
                <p className=' text-white/60'>We specialize in blockchain, AI, DeFi, and NFTs, staying ahead of industry trends.</p>
                <button className='border border-[#E8BA3C] rounded-xl px-5 py-1 mt-2'>Read more</button>
              </div>
              <div className='w-[90%] md:w-[30%] text-start flex-shrink-0'>
                <img src='/assets/c2.png'></img>
                <p className=' font-bold mt-2'> Proven Innovation:</p>
                <p className=' text-white/60'>Turning visionary ideas into succesful products. Driving innovation with a commitment to growth</p>
                <hr className='my-3 w-[20%]' />
                <button className='text-[#E8BA3C] text-sm'>Read more</button>
              </div>
              <div className='w-[90%] md:w-[30%] text-start flex-shrink-0'>
                <img src='/assets/c3.png'></img>
                <p className=' font-bold mt-2'>Customized solutions</p>
                <p className=' text-white/60'>An innovation hub and Consultation platform, Tailored to meet your unique challenges and goals</p>
                <hr className='my-3 w-[20%]' />
                <button className='text-[#E8BA3C] text-sm'>Read more</button>
              </div>
            </div>
            <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500 blur-sm"></span>
          </div>
        </div>
        <div className='Section-6 overflow-hidden bg-gradient-to-t from-[#185CC2D9] via-[#0A2973D9] to-[#9e9055]/80'>
          <div className='pt-20 pb-40 md:pb-0 bg-black/30 backdrop-blur-sm grid content-center text-center'>
            <h1 className='uppercase tracking-widest text-2xl md:text-4xl 2xl:text-5xl font-medium'>How innovative ideas work <br className='hidden md:block' /> with FABC labs?</h1>
            <div className='w-[55%] mx-auto'>
              <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
              <p className='border-b border-white/50'></p>
            </div>
            <div className='relative '>
              <div className='relative flex justify-center items-center '>
                <img src='/assets/dd3d 1.png' className='w-[75%] md:w-[40%] brightness-75 mx-auto z-10'></img>
                <div className='absolute w-[10rem] h-[10rem] md:w-[20rem] md:h-[20rem] border border-white/30 rounded-full z-0 animate-pingslow transform transition-transform duration-500 ease-in-out'></div>
                <div className='absolute w-[15rem] h-[15rem] md:w-[30rem] md:h-[30rem] border border-white/40 rounded-full z-0 animate-pingslow2 transform transition-transform duration-500 ease-in-out'></div>
                <div className='absolute w-[20rem] h-[20rem] md:w-[40rem] md:h-[40rem] border border-white/50 rounded-full z-0 animate-pingslow3 transform transition-transform duration-500 ease-in-out'></div>
              </div>
              <div className='absolute -bottom-[10rem] md:bottom-20 z-10'>
                <div className='w-[90%] relative bg-[#0E1837]/45 border border-[#FFBB00A6] mx-auto p-7 flex flex-col md:flex-row gap-5 md:gap-0'>
                  <div className='md:w-[75%] text-start'><p>An Innovation Hub and a Consultation Platform- a one-stop solution for assistance in building blockchain-related solutions, developing prototypes, and exploring emerging technologies such as Generative AI.</p></div>
                  <div className='md:w-[25%] flex justify-center items-center'>
                    <button className="relative overflow-hidden px-5 py-3 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400  shadow-md">
                      Explore Opportunities
                      <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
                    </button>

                  </div>
                  <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500 blur-sm"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Section-7  bg-gradient-to-b from-[#185CC2D9] via-[#0A2973D9] to-[#9e9055]/80'>
          <div className='py-20 bg-black/30 backdrop-blur-sm grid content-center text-center'>
            <h1 className='uppercase tracking-widest text-4xl 2xl:text-5xl font-medium'>How do we innovate?</h1>
            <div className='w-[50%] mx-auto'>
              <img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img>
              <p className='border-b border-white/50'></p>
            </div>
            <p className='w-[90%] mx-auto mt-5 text-lg 2xl:text-2xl'>We are committed to pushing the boundaries of technology, exploring the uncharted, and transforming visionary ideas into tangible solutions.</p>
            <div className='flex lg:justify-center gap-5 w-[90%] lg:w-[95%] mx-auto mt-5 overflow-x-auto '>
              <div className='flex-shrink-0 w-[90%] md:w-[40%] lg:w-[23%] bg-[#1B2D65] border-[2px] border-[#4F93FF] px-2 pt-2'>
                <img src='/assets/Mask group.png' className='mx-auto'></img>
                <p className='text-white/55 my-3'>Tailored solutions that deliver impactful, relevant results.</p>
                <p className='uppercase font-semibold tracking-widest'>Client-Centric <br />Innovation</p>
                <div className='flex justify-center'>
                  <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5'>02</p>
                </div>
              </div>
              <div className='flex-shrink-0 w-[90%] md:w-[40%] lg:w-[23%] relative flex items-start group'>
                <div className='opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7 w-full'>
                  <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
                  <img src='/assets/CE_01.png' className='mx-auto w-[75%] mt-5'></img>
                  <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest'>Collaborative</p>
                  <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest'>Ecosystem</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7'>01</p>
                  </div>
                </div>
                <div className='absolute opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65] border-[2px] border-[#4F93FF] px-2 pt-2'>
                  <img src='/assets/Mask group.png' className='mx-auto'></img>
                  <p className='text-white/55 my-3'>Tailored solutions that deliver impactful, relevant results.</p>
                  <p className='uppercase font-semibold tracking-widest'>Collaborative Ecosystem</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5'>01</p>
                  </div>
                </div>
              </div>

              <div className='flex-shrink-0 w-[90%] md:w-[40%] lg:w-[23%] relative flex items-start group'>
                <div className='w-full opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7'>
                  <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
                  <img src='/assets/CE_03.png' className='mx-auto w-[75%] mt-5'></img>
                  <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest'>Continuous</p>
                  <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest'>R & D</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7'>03</p>
                  </div>
                </div>
                <div className='absolute opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65] border-[2px] border-[#4F93FF] px-2 pt-2'>
                  <img src='/assets/Mask group.png' className='mx-auto'></img>
                  <p className='text-white/55 my-3'>Tailored solutions that deliver impactful, relevant results.</p>
                  <p className='uppercase font-semibold tracking-widest'>Continuous <br /> R & D</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5'>03</p>
                  </div>
                </div>
              </div>
              <div className='flex-shrink-0 w-[90%] md:w-[40%] lg:w-[23%] relative flex items-start group'>
                <div className='w-full opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7'>
                  <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
                  <img src='/assets/CE_04.png' className='mx-auto w-[75%] mt-5'></img>
                  <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest'>Flexibility</p>
                  <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest'>and Agility</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[20%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7'>04</p>
                  </div>
                </div>
                <div className='absolute opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65] border-[2px] border-[#4F93FF] px-2 pt-2'>
                  <img src='/assets/Mask group.png' className='mx-auto'></img>
                  <p className='text-white/55 my-3'>Tailored solutions that deliver impactful, relevant results.</p>
                  <p className='uppercase font-semibold tracking-widest'>Flexibility<br /> And Agility</p>
                  <div className='flex justify-center'>
                    <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5'>04</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Section-8 relative flex flex-col md:flex-row items-center justify-center'>
          <video
            autoPlay
            loop
            muted
            playsinline
            className="absolute z-0  w-full h-full object-cover">
            <source src="/assets/Other Section  Nodes.mp4" type="video/mp4" />
          </video>
          <div className='text-center bg-gradient-to-b from-[#0e1837]/85 via-[#0e1837] to-[#0e1837]/85  z-20 min-h-screen py-10 w-[100%] grid content-center'>
            <h1 className='uppercase tracking-widest text-4xl 2xl:text-5xl font-medium'>Our Leaders </h1>
            <div className='w-[32%] mx-auto'>
              <img className='animate-moveSmaller1 lg:animate-moveLarger' src='/assets/Subtract (1).png'></img>
              <p className='border-b border-white/50'></p>
            </div>
            <p className='w-[90%] mx-auto mt-5 text-lg 2xl:text-2xl'>At FABC, we're proud to collaborate with a diverse network of global Consortium partners who share our commitment to innovation and excellence. These strategic alliances are the cornerstone of our success, enabling us to deliver cutting-edge solutions and drive positive change across industries.</p>
            <div className='flex flex-col md:flex-row justify-around w-[90%] mx-auto mt-5'>
              <div className='md:w-[25%] relative flex justify-center'>
                <img src='/assets/sak.png'></img>
                <div className='absolute bottom-0'>
                  <p className='font-bold uppercase'>Sakthi Visakan <br />Rajaguru</p>
                  <p className='text-xl mt-2 text-[#E8BA3C] font-medium'>CEO</p>
                </div>
              </div>
              <div className='md:w-[25%] relative flex justify-center'>
                <img src='/assets/ann.png'></img>
                <div className='absolute bottom-0'>
                  <p className='font-bold uppercase'>Annamalai <br /> Palaniappan</p>
                  <p className='text-xl mt-2 text-[#E8BA3C] font-medium'>COO</p>
                </div>
              </div>
              <div className='md:w-[25%] relative flex justify-center'>
                <img src='/assets/anu.png'></img>
                <div className='absolute bottom-0'>
                  <p className='font-bold uppercase'>Anup <br />Kumar</p>
                  <p className='text-xl mt-2 text-[#E8BA3C] font-medium'>CTO</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './route';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
import { Web3Modal } from '@web3modal/ethers5';

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'f5cf9cddd50eb4214c40f53f3e1652bb'
 
// 2. Set chains
const mainnet = {
  chainId: 42161,  
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',  
  rpcUrl: 'https://arb1.arbitrum.io/rpc'  
}
const polygon = {
  chainId: 137, // Chain ID for Polygon (Matic)
  name: 'Polygon',
  currency: 'MATIC', // Update with the correct currency if needed
  explorerUrl: 'https://explorer-mainnet.maticvigil.com/', // Update with the correct explorer URL
  rpcUrl: 'https://rpc-mainnet.maticvigil.com/', // Update with the correct RPC URL
};
// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://skilleareum.ai', // origin must match your domain & subdomain
  icons: ['https://skilleareum.ai/assets/logo.png']
}
const ethersConfig = defaultConfig({
  // /Required/
  metadata,

  // /Optional/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 137, // used for the Coinbase SDK
})
createWeb3Modal({
  ethersConfig,
  chains: [mainnet,polygon],
  projectId,
  themeVariables: {
  '--w3m-accent	':'#0db1a9',
  },
 
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routers/>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 